<template>
<b-card>
  <validation-observer ref="productForm">
  <b-form @submit.prevent="saveUpdateChanges">
    <b-row>
        <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Cuerpo *">
               <validation-provider
                  #default="{ errors }"
                  name="cuerpo"
                  rules="required">
                  <b-form-input v-model="formData.body"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group label="Permanente" label-cols="10">
               <validation-provider
                  #default="{ errors }"
                  name="cuerpo"
                  rules="required">
                  <b-form-checkbox
                    v-model="formData.requireInteraction"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
            
            <b-form-group label="Publico" label-cols="10">
               <validation-provider
                  #default="{ errors }"
                  name="publico"
                  rules="required">
                  <b-form-checkbox
                    v-model="formData.public"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group label="Duración en Milisegundos *" v-if="!formData.requireInteraction">
                <validation-provider
                  #default="{ errors }"
                  name="tiempo"
                  rules="required">
                  <v-select v-model="formData.timeout" :options="timeoutOptions"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            
        </b-col>

        <b-col md="6" xl="4" class="mb-1">
          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                ref="previewEl"
                :src="urlPreview"
                :variant="`light-success`"
                size="150px"
                rounded
              />
            </template>
            <div class="d-flex flex-wrap">
              <b-button variant="primary" @click="$refs.refInputEl.click()">
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="uploadImage">
                <feather-icon
                  icon="UploadIcon"
                  class="d-inline mr-sm-50"
                />
                <span class="d-none d-sm-inline">Subir</span>
              </b-button>
            </div>
          </b-media>

           <b-button
            type="submit"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'">
            <feather-icon :icon="modeEdit ? 'EditIcon' : 'SaveIcon'" size="16" class="mr-50"/>
              {{ modeEdit ? 'Actualizar': 'Guardar' }}
            </b-button>

          <b-button
            @click="preview()"
            variant="warning"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'">
            <feather-icon :icon="'BellIcon'" size="16" class="mr-50"/>
              Vista Previa
            </b-button>
        </b-col>
    </b-row>
  </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BMedia, BAvatar, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, } from '@validations'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
localize('es', es)
import Push from 'push.js'

export default {
  components: {
    BMedia, 
    BAvatar,
    BCard, 
    BRow, 
    BCol, 
    BForm, 
    BFormGroup, 
    BFormInput, 
    BButton, 
    BInputGroup, 
    BInputGroupPrepend, 
    vSelect, 
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox
  },
  data() {
    return {
      required,
      base64: null,
      urlPreview: null,
      formData: {
        body: null,
        timeout: null,
        requireInteraction: false,
        public: false,
      }
    }
  },
  computed: {
    modeEdit() {
      if( this.$router.currentRoute.name === 'notification-edit' ) {
        return this.$router.currentRoute.params.id
      }
    },
    timeoutOptions(){
      return [1, 2, 3, 4, 5].map(x => x * 1000)   
    }
  },
  methods: {
    preview(){
      if(this.base64) {
        this.formData.icon = this.base64
      } else {
        this.formData.icon = this.urlPreview
      }
      Push.create('Andino App', this.formData)
    },
    async saveUpdateChanges() {
      if(this.modeEdit) {
        this.update()
      } else {
        this.create()
      }
    },
    async create() {
      if (!await this.$refs.productForm.validate()) {
        return
      }

      if(this.base64) {
        this.formData.icon = this.base64
      }

      await this.$store.dispatch('notifications/store', this.formData)
      .then(() => {
        this.message('checkCircleIcon', 'success', 'Se registro la notificación exitosamente')
        this.$router.push({ name: 'notifications' })
      })
      .catch((error) => {
        this.message('AlertCircleIcon', 'danger', error.response.data.message)
      })
    },
    async update() {

      if(this.base64) {
        this.formData.icon = this.base64
      }
      
      await this.$store.dispatch('notifications/update', { id: this.modeEdit, data: this.formData})
      .then(() => {
        this.message('checkCircleIcon', 'success', 'Se actualizo la notificación exitosamente')
        this.$router.push({ name: 'notifications' })
      })
      .catch((error) => {
        this.message('AlertCircleIcon', 'danger', error.response.data.message)
      })
    },
    async getData(){
      if (this.modeEdit) {
        const res = await this.$store.dispatch('notifications/show', this.modeEdit)
        this.urlPreview = res.icon
        this.formData.body = res.body 
        this.formData.timeout =  res.timeout 
        this.formData.requireInteraction = res.requireInteraction === 1 ? true : false 
      }
    },
    uploadImage() {
      const { inputImageRenderer } = useInputImageRenderer(this.$refs.refInputEl, this.getImage)

      inputImageRenderer()
    },
    getImage(base64) {
      this.base64 = base64
      this.urlPreview = base64
    },
    message(icon, variant, text) {
      this.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Respuesta',
        icon: icon,
        variant: variant,
        text: text,
      }})
    },
  },
  mounted(){
    this.getData()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>